import { defineStore } from 'pinia'
import { Balance, BalanceMove, MyBuyersBalanceMove } from '@/models/index'
import { useUserStore } from '@/stores/user'

import moment from 'moment'

interface State {
  balanceById: Balance
  balances: Balance[]
  balancesLoading: boolean
  selectedBalance: Balance | null
  balanceMoves: BalanceMove[] | MyBuyersBalanceMove[],
  balanceMovesLoading: boolean
  lastThreeTopup: MyBuyersBalanceMove[],
  lastThreeTopupLoading: boolean,
  filters: any
  total: number
  totalBalances: number
  pagination: any
  headersByDate: any[]
}

export const useBalanceStore = defineStore('balance', {
  state: (): State => ({
    balanceById: new Balance({ id: '' }),
    balances: [],
    balancesLoading: false,
    selectedBalance: new Balance({ id: '' }),
    balanceMoves: [],
    balanceMovesLoading: false,
    lastThreeTopup: [],
    lastThreeTopupLoading: false,
    filters: {
      number: null,
      giftCardId: null,
      dateRange: null,
      buyerId: null,
      sellerId: null,
      reason: null
    },
    total: 0,
    totalBalances: 0,
    pagination: {
      page: 1,
      limit: 20
    },
    headersByDate: []
  }),
  actions: {
    async fetchBalanceById(balanceId: string) {
      this.balancesLoading = true

      const { data } = await Balance
        .includes(['seller', 'giftCard'])
        .where({ sellerId: this.filters.sellerId })
        .find(balanceId)

      this.balanceById = data
      this.balancesLoading = false
    },
    async fetchBalances() {
      this.balancesLoading = true
      const { data, meta } = await Balance
          .includes(['seller', 'giftCard'])
          .where({ sellerId: this.filters.sellerId })
          .per(this.pagination.limit)
          .page(this.pagination.page)
          .stats({ total: 'count' })
          .all()

      this.balances.push(...data)
      this.totalBalances = meta.stats.total.count
      this.balancesLoading = false
    },
    async fetchBalanceMoves(buyer?: boolean) {
      const user = useUserStore()

      this.balanceMovesLoading = true
      const createdAtGte = this.filters.dateRange ? moment(String(this.filters.dateRange![0])).startOf('day').format('YYYY-MM-DD HH:mm:ss') : ''
      const createdAtLte = this.filters.dateRange ? moment(String(this.filters.dateRange![1])).endOf('day').format('YYYY-MM-DD HH:mm:ss') : ''

      const model = buyer ? MyBuyersBalanceMove : BalanceMove
      const includes = buyer ? { source: [ 'giftCard' ] } : { source: [ 'giftCard', { buyerBalanceMove: { balance: 'buyer' } } ] }

      const { data, meta } = await model
        .includes(includes)
        .where({
          balanceId: this.selectedBalance?.id,
          sourceNumber: { prefix: this.filters.number },
          giftCardId: this.filters.giftCardId,
          createdAt: { gte: createdAtGte, lte: createdAtLte },
          buyerId: this.filters.buyerId,
          reason: this.filters.reason
        })
        .select({ giftCards: ['name', 'logoSquare'] })
        .order({ createdAt: 'desc' })
        .page(this.pagination.page)
        .per(this.pagination.limit)
        .stats({ total: 'count' })
        .all()

      this.balanceMoves.push(...data)
      this.total = meta.stats.total.count
      this.balanceMovePerDate()
      this.balanceMovesLoading = false
    },
    async fetchLastThreeTopupBuyer() {
      this.lastThreeTopupLoading = true

      const { data } = await MyBuyersBalanceMove
        .includes({ source: [ 'giftCard' ] })
        .where({
          buyerId: this.filters.buyerId,
          reason: 'topup'
        })
        .order({ createdAt: 'desc' })
        .page(1)
        .per(3)
        .all()

      this.lastThreeTopup = data
      this.lastThreeTopupLoading = false
    },
    resetFilters(): void {
      this.selectedBalance = null
      this.filters = {
        number: null,
        giftCardId: null,
        dateRange: null,
        buyerId: null,
        sellerId: null,
        reason: null
      }
    },
    async resetPagination(): Promise<void> {
      this.pagination.page = 1
      this.balanceMoves = []
      this.balances = []
      this.headersByDate = []
      this.total = 0
    },
    balanceMovePerDate(): void {
      this.headersByDate = []
      this.balanceMoves.map((e, index) => {
        if (this.balanceMoves[index - 1] && moment(this.balanceMoves[index - 1].createdAt).format('L') !== moment(e.createdAt).format('L')) {
          this.headersByDate.push(index)
        }
      })
    },
    clearSettings() {
      this.$reset()
    }
  }
})
