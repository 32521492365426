import { defineStore } from 'pinia'
import { PurchaseVolume } from '@/models'
import { useUserStore } from '@/stores/user'

interface State {
  loading: boolean,
  purchaseVolumes: any[],
  period: string,
  salesMode: boolean,
  volumesBy: string,
  volumesChartFullscreen: boolean
}

export const useDashboardStore = defineStore('dashboard', {
  state: (): State => ({
    loading: false,
    purchaseVolumes: [],
    period: 'day',
    salesMode: false,
    volumesBy: 'giftCards',
    volumesChartFullscreen: false
  }),
  getters: {
    colors(): string[] {
      return [
        '#FF6384', '#36A2EB', '#FFCE56', '#F58231', '#46F0F0', '#D2F53C', '#911EB4', '#F032E6',
        '#3CB44B', '#FFE119', '#E6194B', '#FABEBE', '#008080', '#E6BEFF', '#0082C8', '#AA6E28',
        '#FFFAC8', '#800000', '#AAFFC3', '#808000', '#FFD8B1', '#000080', '#808080', '#000000'
      ]
    },
    isWholesaler() {
      const userStore: any = useUserStore()
      return userStore.roles.includes('wholesaler')
    },
    purchaseCount: (state): number => {
      return state.purchaseVolumes.reduce((acc: number, current: PurchaseVolume): number => {
        return acc + current.purchaseCount
      }, 0)
    },
    totalAmount: (state): number => {
      return state.purchaseVolumes.reduce((acc: number, current: PurchaseVolume): number => {
        return acc + (current.amount * -1)
      }, 0)
    },
    faceValueTotalAmount: (state): number => {
      return state.purchaseVolumes.reduce((acc: number, current: PurchaseVolume): number => {
        return acc + (current.faceValueAmount)
      }, 0)
    },
    chartVolumesData(state): any {
      let chartBarData: any = null

      switch(state.period) {
        case 'day':
          if (state.volumesBy === 'giftCards') chartBarData = this.chartBarDataOfDay
          else if (state.volumesBy === 'buyers') chartBarData = this.chartBarDataOfDayByBuyers
          break
        case 'week':
          if (state.volumesBy === 'total') chartBarData = this.chartBarDataOfWeek
          else if (state.volumesBy === 'giftCards') chartBarData = this.chartBarDataOfWeekByGiftCards
          else if (state.volumesBy === 'buyers') chartBarData = this.chartBarDataOfWeekByBuyers
          break
        case 'last_month':
        case 'month':
          if (state.volumesBy === 'total') chartBarData = this.chartBarDataOfMonth
          else if (state.volumesBy === 'giftCards') chartBarData = this.chartBarDataOfMonthByGiftCards
          else if (state.volumesBy === 'buyers') chartBarData = this.chartBarDataOfMonthByBuyers
          break
        case 'year':
          chartBarData = this.chartBarDataOfYear
          break
        default:
          chartBarData = this.chartBarDataOfDayByHours
          break
      }

      return chartBarData
    },
    chartBarDataOfDay(): any {
      const data = new Map()

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const key = e.giftCard.name
        if (!data.get(key)) data.set(key, 0)

        data.set(key, (data.get(key) + e.faceValueAmount))
      })

      return {
        labels: [...data.keys()],
        datasets: [{
          backgroundColor: this.colors,
          data: [...data.values()]
        }]
      }
    },
    chartBarDataOfDayByBuyers(): any {
      const data = new Map()

      this.purchaseVolumes.forEach((e: any) => {
        const key = e.balance.buyer.name
        if (!data.get(key)) data.set(key, 0)

        data.set(key, (data.get(key) + e.faceValueAmount))
      })

      return {
        labels: [...data.keys()],
        datasets: [{
          backgroundColor: this.colors,
          data: [...data.values()]
        }]
      }
    },
    chartBarDataOfDayByHours(state): any {
      const data = new Map()

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const hour = new Date(e.createdAt).getHours()
        const string_hour = `${hour}:00`
        if (!data.get(string_hour)) data.set(string_hour, 0)

        data.set(string_hour, (data.get(string_hour) + e.faceValueAmount))
      })

      return {
        labels: [...data.keys()],
        backgroundColor: [],
        datasets: [{
          label: 'Montant VF',
          backgroundColor: '#41B883',
          data: [...data.values()]
        }]
      }
    },
    chartBarDataOfWeek(state): any {
      const data: any = {}
      const labels = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const day = new Date(e.createdAt).getDay()
        const string_day = labels[day]
        if (!data[string_day]) data[string_day] = 0

        data[string_day] += e.faceValueAmount
      })

      return {
        labels: Object.keys(data),
        backgroundColor: [],
        datasets: [{
          label: 'Montant VF',
          backgroundColor: this.colors[0],
          data: Object.values(data)
        }]
      }
    },
    chartBarDataOfWeekByGiftCards(state): any {
      const data = new Map()
      const labels = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

      const topFive = this.giftCardsLeaderboard.slice(0, 5)
      const topFiveIds = topFive.map((e: any) => e.giftCard.id)

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const day = new Date(e.createdAt).getDay()
        const string_day = labels[day]
        if (!labels.includes(string_day)) labels.push(string_day)
      })

      topFive.forEach((e: PurchaseVolume, index: number) => {
        data.set(e.giftCard.id, { label: e.giftCard.name, backgroundColor: this.colors[index], data: new Array(labels.length).fill(0) })
      })
      data.set('others', { label: 'Autres', backgroundColor: this.colors[topFive.length], data: new Array(labels.length).fill(0) })

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const day = new Date(e.createdAt).getDay()
        const string_day = labels[day]
        const key = topFiveIds.includes(e.giftCard.id) ? e.giftCard.id : 'others'
        const index = labels.indexOf(string_day)

        data.get(key).data[index] += e.faceValueAmount
      })

      return {
        labels: labels,
        datasets: [...data.values()]
      }
    },
    chartBarDataOfWeekByBuyers(state): any {
      const data = new Map()
      const labels = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi']

      const topFive = this.buyersLeaderboard.slice(0, 5)
      const topFiveIds = topFive.map((e: any) => e.buyer.id)

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const day = new Date(e.createdAt).getDay()
        const string_day = labels[day]
        if (!labels.includes(string_day)) labels.push(string_day)
      })

      topFive.forEach((e: any, index: number): void => {
        data.set(e.buyer.id, { label: e.buyer.name, backgroundColor: this.colors[index], data: new Array(labels.length).fill(0) })
      })
      data.set('others', { label: 'Autres', backgroundColor: this.colors[topFive.length], data: new Array(labels.length).fill(0) })

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const day = new Date(e.createdAt).getDay()
        const string_day = labels[day]
        const key = topFiveIds.includes(e.balance.buyer.id) ? e.balance.buyer.id : 'others'
        const index = labels.indexOf(string_day)

        data.get(key).data[index] += e.faceValueAmount
      })

      return {
        labels: labels,
        datasets: [...data.values()]
      }
    },
    chartBarDataOfMonth(state): any {
      const data = new Map()

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const day = new Date(e.createdAt).getDate()
        const month = new Date(e.createdAt).getMonth() + 1
        const string_day = `${day}/${month}`
        if (!data.get(string_day)) data.set(string_day, 0)

        data.set(string_day, (data.get(string_day) + e.faceValueAmount))
      })

      return {
        labels: [...data.keys()],
        backgroundColor: [],
        datasets: [{
          label: 'Montant VF',
          backgroundColor: this.colors[0],
          data: [...data.values()]
        }]
      }
    },
    chartBarDataOfMonthByGiftCards(state): any {
      const labels: string[] = []
      const data = new Map()

      const topFive = this.giftCardsLeaderboard.slice(0, 5)
      const topFiveIds = topFive.map((e: any) => e.giftCard.id)

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const day = new Date(e.createdAt).getDate()
        const month = new Date(e.createdAt).getMonth() + 1
        const string_day = `${day}/${month}`
        if (!labels.includes(string_day)) labels.push(string_day)
      })

      topFive.forEach((e: PurchaseVolume, index: number) => {
        data.set(e.giftCard.id, { label: e.giftCard.name, backgroundColor: this.colors[index], data: new Array(labels.length).fill(0) })
      })
      data.set('others', { label: 'Autres', backgroundColor: this.colors[topFive.length], data: new Array(labels.length).fill(0) })

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const day = new Date(e.createdAt).getDate()
        const month = new Date(e.createdAt).getMonth() + 1
        const string_day = `${day}/${month}`
        const key = topFiveIds.includes(e.giftCard.id) ? e.giftCard.id : 'others'
        const index = labels.indexOf(string_day)

        data.get(key).data[index] += e.faceValueAmount
      })

      return {
        labels: labels,
        datasets: [...data.values()]
      }
    },
    chartBarDataOfMonthByBuyers(state): any {
      const labels: string[] = []
      const data = new Map()
      const topFive = this.buyersLeaderboard.slice(0, 5)
      const topFiveIds = topFive.map((e: any) => e.buyer.id)

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const day = new Date(e.createdAt).getDate()
        const month = new Date(e.createdAt).getMonth() + 1
        const string_day = `${day}/${month}`
        if (!labels.includes(string_day)) labels.push(string_day)
      })

      topFive.forEach((e: any, index: number): void => {
        data.set(e.buyer.id, { label: e.buyer.name, backgroundColor: this.colors[index], data: new Array(labels.length).fill(0) })
      })
      data.set('others', { label: 'Autres', backgroundColor: this.colors[topFive.length], data: new Array(labels.length).fill(0) })

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const day = new Date(e.createdAt).getDate()
        const month = new Date(e.createdAt).getMonth() + 1
        const string_day = `${day}/${month}`
        const key = topFiveIds.includes(e.balance.buyer.id) ? e.balance.buyer.id : 'others'
        const index = labels.indexOf(string_day)

        data.get(key).data[index] += e.faceValueAmount
      })

      return {
        labels: labels,
        datasets: [...data.values()]
      }
    },
    chartBarDataOfYear(state): any {
      const data = new Map()
      const labels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre',
        'Octobre', 'Novembre', 'Décembre']

      this.purchaseVolumes.forEach((e: PurchaseVolume) => {
        const month = new Date(e.createdAt).getMonth()
        const string_month = labels[month]
        if (!data.get(string_month)) data.set(string_month, 0)

        data.set(string_month, (data.get(string_month) + e.faceValueAmount))
      })

      return {
        labels: [...data.keys()],
        backgroundColor: [],
        datasets: [{
          label: 'Montant VF',
          backgroundColor: this.colors[0],
          data: [...data.values()]
        }]
      }
    },
    giftCardsLeaderboard(state): any {
      const totalByGiftCardId = this.purchaseVolumes.reduce((acc: any, current: PurchaseVolume): any => {
        const giftCardId = current.giftCard.id
        if (!acc.get(giftCardId))
          acc.set(giftCardId, { giftCard: current.giftCard, amount: 0 })
        acc.get(giftCardId).amount += current.faceValueAmount

        return acc
      }, new Map)

      const sortByAmount = new Map([...totalByGiftCardId.entries()].sort((a: any, b: any) => b[1].amount - a[1].amount))

      return [...sortByAmount.values()]
    },
    buyersLeaderboard(state): any {
      const totalByBuyerId = this.purchaseVolumes.reduce((acc: any, current: PurchaseVolume): any => {
        const buyerId = current.balance.buyer.id
        if (!acc.get(buyerId))
          acc.set(buyerId, { buyer: current.balance.buyer, amount: 0 })
        acc.get(buyerId).amount += current.faceValueAmount

        return acc
      }, new Map)

      const sortByAmount = new Map([...totalByBuyerId.entries()].sort((a: any, b: any) => b[1].amount - a[1].amount))

      return [...sortByAmount.values()]
    }
  },
  actions: {
    async getPurchaseVolumes(): Promise<void> {
      this.loading = true

      const includes = this.isWholesaler ? ['giftCard', { balance: 'buyer' }] : 'giftCard'

      const { data } = await PurchaseVolume
        .where({
          period: this.period,
          salesMode: this.salesMode
        })
        .order({ createdAt: 'asc' })
        .includes(includes)
        .per(999)
        .page(1)
        .all()

      this.purchaseVolumes = data
      this.loading = false
    }
  }
})
