import { PurchaseInfo } from '@/models/index'
import { percents } from '@/utils/math'

export default class OrderDiscountCalculator {
  private VAT = 20

  public isBuyer = true

  public sellerPurchaseInfo!: PurchaseInfo
  public wholesalerPurchaseInfo!: PurchaseInfo

  public values: any = {
    discount: 0,
    discountWithoutVat: 0,
    sellerDiscount: 0,
    sellerDiscountWithoutVat: 0,
    discountedAmount: 0,
    discountedAmountWithoutVat: 0,
    chargeableAmount: 0,
    payableAmount: 0,
    balanceEarned: 0
  }

  public get baseAmount(): number {
    return this._baseAmount
  }

  public set baseAmount(baseAmount: number) {
    this._baseAmount = baseAmount
    this.calculate()
  }

  public get balanceBurned(): number {
    return this._balanceBurned
  }

  public set balanceBurned(balanceBurned: number) {
    this._balanceBurned = balanceBurned
    this.calculate()
  }

  private _baseAmount = 0
  private _balanceBurned = 0

  constructor(sellerPurchaseInfo: PurchaseInfo, wholesalerPurchaseInfo: PurchaseInfo, isBuyer: boolean) {
    this.sellerPurchaseInfo = sellerPurchaseInfo
    this.wholesalerPurchaseInfo = wholesalerPurchaseInfo
    this.isBuyer = isBuyer
  }

  get sellerDiscount(): number {
    return this.sellerPurchaseInfo.rate.amount
  }

  get discount(): number {
    return this.isBuyer ? this.wholesalerPurchaseInfo.rate.amount : this.sellerDiscount
  }

  get isIntracom() {
    return (this.wholesalerPurchaseInfo || this.sellerPurchaseInfo).isVatIrrelevant()
  }

  get inCommissionVatPayback() {
    return this.wholesalerPurchaseInfo.inCommissionVatPayback()
  }

  get isCompleteOffset() {
    return this.wholesalerPurchaseInfo.isCompleteOffset()
  }

  get isVatPaid() {
    return this.wholesalerPurchaseInfo.isVatPaid()
  }

  get isPartialOffset() {
    return this.wholesalerPurchaseInfo.isPartialOffset()
  }

  get vatIncluded() {
    return (this.sellerPurchaseInfo || this.wholesalerPurchaseInfo)?.rate?.rateCard?.vatIncluded
  }

  get chargeableAmount() {
    if (this.isCompleteOffset && (this.isVatPaid || this.inCommissionVatPayback)) {
      return this.values.discountedAmount
    } else if (!this.isVatPaid || this.isPartialOffset) {
      return this.values.discountedAmountWithoutVat
    } else return this._baseAmount
  }

  private calculate() {
    this.assignDiscounts()
    this.assignAmounts()
    this.assignFinals()
    this.assignBalance()

    return this.values
  }

  private assignDiscounts() {
    this.values.discount = this.isIntracom ? this.withoutVat(this.discount) : this.withVat(this.discount)
    this.values.discountWithoutVat = this.withoutVat(this.discount)

    this.values.sellerDiscount = this.isIntracom ? this.withoutVat(this.sellerDiscount) : this.withVat(this.sellerDiscount)
    this.values.sellerDiscountWithoutVat = this.withoutVat(this.sellerDiscount)
  }

  private assignAmounts() {
    this.values.discountedAmount = this._baseAmount - percents(this.values.discount, this._baseAmount)
    this.values.discountedAmountWithoutVat = this._baseAmount - percents(this.values.discountWithoutVat, this._baseAmount)
  }

  private assignFinals() {
    this.values.chargeableAmount = this.chargeableAmount
    this.values.payableAmount = this.values.chargeableAmount - (this._balanceBurned || 0)
    if (this.values.payableAmount < 0) this.values.payableAmount = 0
  }

  private assignBalance() {
    if (!this.isPartialOffset && !this.isVatPaid) return 0

    this.values.balanceEarned = this.values.chargeableAmount - this.values.discountedAmount
  }

  private withVat(amount: number) {
    if (this.vatIncluded || this.inCommissionVatPayback) {
      return amount
    } else return amount + percents(this.VAT, amount)
  }

  private withoutVat(amount: number) {
    if (this.vatIncluded || this.inCommissionVatPayback) {
      return amount / (1 + (this.VAT / 100))
    } else return amount
  }
}
