import { defineStore } from 'pinia'
import { RouteConfig } from 'vue-router'
import { asyncRoutes, constantRoutes } from '@/router'
import { useUserStore } from '@/stores/user'

const hasPermission = (roles: string[], route: RouteConfig) => {
  const userStore = useUserStore()

  if (route.meta && route.meta.roles && route.meta.excludeAccounts) {
    return roles.some(role => route?.meta?.roles.includes(role) && !route?.meta?.excludeAccounts.includes(userStore?.model?.manageable.name))
  } else if (route.meta && route.meta.roles) {
    return roles.some(role => route?.meta?.roles.includes(role))
  } else {
    return true
  }
}

export const filterAsyncRoutes = (routes: RouteConfig[], roles: string[]) => {
  const res: RouteConfig[] = []
  routes.forEach(route => {
    const r = { ...route }
    if (hasPermission(roles, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, roles)
      }
      res.push(r)
    }
  })
  return res
}

interface State {
  routes: RouteConfig[]
  dynamicRoutes: RouteConfig[]
  routesGenerated: boolean
}

export const usePermissionStore = defineStore('permission', {
  state: (): State => ({
    routes: [],
    dynamicRoutes: [],
    routesGenerated: false
  }),
  actions: {
    generateRoutes(roles: string[]) {
      let accessedRoutes

      if (roles.includes('admin')) accessedRoutes = asyncRoutes
      else accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)

      this.routes = constantRoutes.concat(accessedRoutes)
      this.dynamicRoutes = accessedRoutes
      this.routesGenerated = true
    }
  }
})
