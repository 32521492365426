const tokenKey = 'jwt'
const refreshTokenKey = 'jwt_refresh_token'

export const getToken = (): string | null => localStorage.getItem(tokenKey)
export const setToken = (token: string): void => localStorage.setItem(tokenKey, token)
export const removeToken = (): void => localStorage.removeItem(tokenKey)

export const getRefreshToken = (): string | null => localStorage.getItem(refreshTokenKey)
export const setRefreshToken = (refreshToken: string): void => localStorage.setItem(refreshTokenKey, refreshToken)
export const removeRefreshToken = (): void => localStorage.removeItem(refreshTokenKey)
