import { defineStore } from 'pinia'
import { User, UserManager } from 'oidc-client-ts'

import ApplicationRecord from '@/models/ApplicationRecord'
import { User as UserModel } from '@/models/index'

import * as Sentry from '@sentry/vue'

import router from '@/router'

function getClientID() {
  let clientId = null

  if (process.env.VUE_APP_ENV === 'production') {
    const host = window.location.hostname

    switch (host) {
      case 'market.walleo.io':
        clientId = "YmgpaG9x4xVJXNX-KxtW0iRgZRK5peZhRtnV6nmZOfU"
        break
      case 'orders.rlc-solutions.com':
        clientId = "TDiVoQgizl6BWTwdllSIzR5u7SXrPjGNZPmiLz7ixl4"
        break
      default:
        clientId = "YmgpaG9x4xVJXNX-KxtW0iRgZRK5peZhRtnV6nmZOfU"
        break
    }
  } else
    clientId = process.env.VUE_APP_CLIENT_ID

  return clientId
}

function getRedirectURI() {
  return process.env.VUE_APP_ENV === 'production' ? `https://${window.location.hostname}` : process.env.VUE_APP_URL
}

interface State {
  userManager: UserManager
  user: User | null
  model: UserModel | null
}

export const useUserStore = defineStore('user', {
  state: (): State => ({
    userManager: new UserManager({
      authority: process.env.VUE_APP_OPENID_SERVER_URL!,
      client_id: getClientID()!,
      redirect_uri: getRedirectURI()!,
      silent_redirect_uri: getRedirectURI(),
      post_logout_redirect_uri: getRedirectURI(),
      response_type: 'code',
      scope: 'openid market',
      automaticSilentRenew: false
    }),
    user: null,
    model: null
  }),
  getters: {
    roles: (state): string[] => [state.user?.profile.role as string],
    accessToken: (state) => state?.user?.access_token,
    isCaretakerIEG: (state) => state?.model?.manageable.name === 'Avantages IEG',
    isWholesaler() {
      return (this.roles as any).includes('wholesaler')
    }
  },
  actions: {
    signinRedirect(): Promise<void> {
      return this.userManager.signinRedirect()
    },
    async signinRedirectCallback(): Promise<User> {
      this.user = await this.userManager.signinRedirectCallback()
      ApplicationRecord.jwtStorage = false
      ApplicationRecord.jwt = this.user.access_token

      return this.user
    },
    async signinSilent(): Promise<User|null> {
      this.user = await this.userManager.signinSilent()
      if (this.user) {
        ApplicationRecord.jwtStorage = false
        ApplicationRecord.jwt = this.user.access_token
      }

      return this.user
    },
    async signout(): Promise<void> {
      await this.userManager.revokeTokens()
      await this.userManager.removeUser()
      localStorage.removeItem('jwt')

      await router.push({ name: 'Login' })
    },
    async signoutRedirect(): Promise<void> {
      await this.userManager.revokeTokens()
      await this.userManager.removeUser()
      localStorage.removeItem('jwt')

      await this.userManager.signoutRedirect()
    },
    async getUser(): Promise<User|null> {
      this.user = await this.userManager.getUser()

      return this.user
    },
    async getManageable() {
      if (!this.user)
        await this.getUser()

      const { data } = await UserModel.includes('manageable').findSingle()
      this.model = data

      Sentry.setUser({ email: this.model?.email || 'email@walleo.io' })
    }
  }
})
