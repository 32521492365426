import { getSidebarStatus, setSidebarStatus, setLanguage } from '@/utils/cookies'
import { getLocale } from '@/lang'
import { defineStore } from 'pinia'

export enum DeviceType {
  Mobile,
  Desktop,
}

interface State {
  device: DeviceType
  sidebar: {
    opened: boolean
    withoutAnimation: boolean
  }
  language: string
}

export const useAppStore = defineStore('app', {
  state: (): State => ({
    sidebar: {
      opened: getSidebarStatus() !== 'closed',
      withoutAnimation: false
    },
    device: DeviceType.Desktop,
    language: getLocale()
  }),
  getters: {
  },
  actions: {
    toggleSideBar(withoutAnimation: boolean) {
      this.sidebar.opened = !this.sidebar.opened
      this.sidebar.withoutAnimation = withoutAnimation
      if (this.sidebar.opened) {
        setSidebarStatus('opened')
      } else {
        setSidebarStatus('closed')
      }
    },
    closeSideBar(withoutAnimation: boolean) {
      this.sidebar.opened = false
      this.sidebar.withoutAnimation = withoutAnimation
      setSidebarStatus('closed')
    },
    toogleDevice(device: DeviceType) {
      this.device = device
    },
    setLanguage(language: string) {
      this.language = language
      setLanguage(this.language)
    },
    clearSettings () {
      this.$reset()
    }
  }
})
