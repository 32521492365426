import { Notification } from 'element-ui'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

export function printErrors(errors: any) {
  const e: (IValidationError<any> | undefined)[] = Object.values(errors)
  for (const error of e) {
    if (error) {
      Notification.error({
        title: error.title,
        message: error.fullMessage,
        type: 'error',
        duration: 5000
      })
    }
  }
}
